if (process.env.REACT_APP_BACKEND_URL === null) throw new Error('Missing env.REACT_APP_BACKEND_URL');

export const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const refreshToken = 'auth/refresh';

export const login = 'auth/login';

export const appointments = 'appointment';

export const regions = 'building';

export const industries = 'industry';

export const appointmentGroups = 'appointment-group';

export const addons = 'addon';

export const users = 'user';

export const client = 'client';

export const lead = 'lead';

export const packageEndpoint = 'package';
