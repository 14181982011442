import * as React from 'react';
import { lazy, ReactElement, Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import App from './app/components/pages/App';
import { AppointmentsMenu } from './app/components/pages/App/organisms/AppointmentsMenu';
import { useAppSelector } from './app/hooks';
import { UserRole } from './store/users/users.type';
import LoginPage from './Pages/LoginPage';
import ResetPasswordPage from './Pages/ResetPasswordPage';

const AddonsPage = lazy(() => import('./Pages/AddonsPage'));
const AppointmentGroupsTablePage = lazy(() => import('./Pages/AppointmentGroups'));
const AppointmentsPage = lazy(() => import('./Pages/AppointmentsPage'));
const CreateAppointmentGroupPage = lazy(() => import('./Pages/CreateAppointmentGroupPage'));
const CreateAppointmentPage = lazy(() => import('./Pages/CreateAppointmentPage'));
const EditAppointmentGroupPage = lazy(() => import('./Pages/EditAppointmentGroupPage'));
const EditAppointmentPagePage = lazy(() => import('./Pages/EditAppointmentPage'));
const IndustriesTable = lazy(() => import('Pages/Industries'));
const Leads = lazy(() => import('Pages/Leads/Leads'));
const Clients = lazy(() => import('Pages/Clients/Clients'));
const ClientProfile = lazy(() => import('Pages/ClientProfile/ClientProfile'));
const MyAvailabilityPage = lazy(() => import('./Pages/MyAvailabilityPage'));
const Report = lazy(() => import('Pages/Report'));
const Schedule = lazy(() => import('Pages/Schedule'));
const UsersPage = lazy(() => import('./Pages/UsersPage'));

export enum RoutesEnum {
  root = '/',
  appointmentGroupCreateNew = '/appointments-menu/appointment-groups/new',
  appointmentGroupEdit = '/appointments-menu/appointment-groups/:appointmentGroupId/edit',
  appointmentGroups = '/appointments-menu/appointment-groups',
  appointments = '/appointments-menu/appointments',
  users = '/users',
  myAvailability = '/myavailability',
  appointmentsEdit = '/appointments-menu/appointments/edit/',
  addons = '/appointments-menu/addons/new',
  appointmentsCreateNew = '/appointments-menu/appointments/new',
  addonsCreateNew = '/appointments-menu/addons/new',
  resetPassword = '/reset-password',
  schedule = '/schedule',
}

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isLoggedIn = useAppSelector(({ auth }) => auth.refreshToken && auth.user);
  return isLoggedIn ? children : <Navigate to="/login" />;
};

const AdminRoutes = () => {
  const user = useAppSelector(({ auth }) => auth.user);
  return user && user.role === UserRole.ADMIN ? <Outlet /> : <Navigate to="/" replace />;
};

const AdminOrManagerRoutes = () => {
  const user = useAppSelector(({ auth }) => auth.user);
  return user && [UserRole.ADMIN, UserRole.MANAGER].includes(user.role) ? <Outlet /> : <Navigate to="/" replace />;
};

const WorkerOrManagerRoutes = () => {
  const user = useAppSelector(({ auth }) => auth.user);
  return user && [UserRole.WORKER, UserRole.MANAGER].includes(user.role) ? <Outlet /> : <Navigate to="/" replace />;
};

const WorkerRoutes = () => {
  const user = useAppSelector(({ auth }) => auth.user);
  return user && user.role === UserRole.WORKER ? <Outlet /> : <Navigate to="/" replace />;
};

const RedirectRoutes = () => {
  const user = useAppSelector(({ auth }) => auth.user);
  if (user && user.role === UserRole.ADMIN) return <Navigate to="/schedule" replace />;
  if (user && user.role === UserRole.MANAGER) return <Navigate to="/schedule" replace />;
  if (user && user.role === UserRole.WORKER) return <Navigate to="/schedule" replace />;
  return <Navigate to="/" />;
};

const LazyComponent = ({ component }: { component: ReactElement }) => (
  <Suspense fallback={<CircularProgress style={{ margin: 'auto' }} />}>{component}</Suspense>
);

export default (
  <Routes>
    <Route
      element={
        <RequireAuth>
          <App />
        </RequireAuth>
      }
    >
      <Route element={<AdminOrManagerRoutes />}>
        <Route path="clients" element={<LazyComponent component={<Clients />} />} />
        <Route path="clients/:id" element={<LazyComponent component={<ClientProfile />} />} />
        <Route path="leads" element={<LazyComponent component={<Leads />} />} />
        <Route path="payroll-reports" element={<LazyComponent component={<Report />} />} />
      </Route>

      <Route element={<AdminRoutes />}>
        <Route path="appointments-menu" element={<AppointmentsMenu />}>
          <Route path="industries" element={<LazyComponent component={<IndustriesTable />} />} />
          <Route path="appointment-groups" element={<LazyComponent component={<AppointmentGroupsTablePage />} />} />
          <Route path="appointment-groups/new" element={<LazyComponent component={<CreateAppointmentGroupPage />} />} />
          <Route
            path="appointment-groups/:appointmentGroupId/edit"
            element={<LazyComponent component={<EditAppointmentGroupPage />} />}
          />
          <Route path="appointments" element={<LazyComponent component={<AppointmentsPage />} />} />
          <Route path="appointments/new" element={<LazyComponent component={<CreateAppointmentPage />} />} />
          {/* todo: refactor route to `/appointments/:id/edit */}
          <Route path="appointments/edit/:id" element={<LazyComponent component={<EditAppointmentPagePage />} />} />
          <Route path="addons" element={<LazyComponent component={<AddonsPage />} />} />
        </Route>
        <Route path="users" element={<LazyComponent component={<UsersPage />} />} />
      </Route>

      <Route element={<WorkerOrManagerRoutes />}>
        <Route path="myavailability" element={<LazyComponent component={<MyAvailabilityPage />} />} />
      </Route>

      <Route path="schedule" element={<LazyComponent component={<Schedule />} />} />
      <Route path="*" element={<RedirectRoutes />} />
    </Route>
    <Route path="login" element={<LoginPage />} />
    <Route path="reset-password" element={<ResetPasswordPage />} />
  </Routes>
);
