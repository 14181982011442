import { Autocomplete, InputAdornment } from '@mui/material';
import debounce from 'lodash/debounce';
import { SvgSearch } from 'svg';
import { DEFAULT_DEBOUNCE_TIME_SEC } from 'shared/constants';
import { SearchFieldProps } from './SearchField.types';
import * as S from './SearchField.styles';

const SearchField = ({
  className,
  formLabel,
  freeSolo = true,
  options,
  placeholder,
  searchTerm,
  setSearchTerm,
}: SearchFieldProps): JSX.Element => (
  <Autocomplete
    className={className}
    disablePortal
    options={options}
    value={searchTerm}
    isOptionEqualToValue={() =>
      // Note: Since this Autocomplete is only used for string[] options
      // I return `false` here to prevent the same options to be highlighted
      false
    }
    onChange={(_, newValue) => {
      setSearchTerm(newValue || '');
    }}
    freeSolo={freeSolo}
    renderOption={(props, option) => (
      <li {...props} key={props.id}>
        {option}
      </li>
    )}
    renderInput={(params) => (
      <S.SearchTextField
        {...params}
        variant="outlined"
        onChange={debounce((e) => setSearchTerm(e.target.value || ''), DEFAULT_DEBOUNCE_TIME_SEC)}
        formLabel={formLabel}
        placeholder={placeholder || 'Search...'}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <SvgSearch />
                </InputAdornment>
            ),
        }}
      />
    )}
  />
);

export default SearchField;
